import { Injectable } from '@angular/core';
import { LinkField } from '@prismicio/client';
import { ProductCatalogService, RequestParams } from '@yol-digital/ms-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Promotion } from 'interfaces';
import { Product, ProductService } from 'product-shared';
import CatalogResponse = ProductCatalogService.CatalogResponse;

@Injectable({
  providedIn: 'root',
})
export class EshopProductService extends ProductService {
  protected getCatalog(query?: { code?: string }, params?: RequestParams) {
    const ziraEnabledProductClasses = this.featureFlagService.getFeatureValue('zira-enabled-product-classes', '');
    return ziraEnabledProductClasses?.length > 0
      ? this.api.public.getCatalog(
          {
            ...query,
            source: 'zira',
            productSpecClass: ziraEnabledProductClasses,
          },
          params
        )
      : this.api.public.getCatalog(query, params);
  }

  public getPromotionByCode(code: string) {
    return this.api.public.getPromotionByCode(code);
  }

  public getPCProducts$(): Observable<CatalogResponse[]> {
    return this.observableDataLoader.get('ProductCatalog', () =>
      this.getCatalog().pipe(map((res: { plans: CatalogResponse[] }) => res.plans))
    );
  }

  public getPCProductByCode(code: string) {
    return this.observableDataLoader.get('PC_product_' + code, () =>
      this.getCatalog({ code }).pipe(
        map((res: { plans: ProductCatalogService.CatalogResponse[] }) =>
          res.plans.find(product => product.code == code)
        )
      )
    );
  }

  public buildCheckoutPath(
    product: Product,
    promotion?: Promotion,
    lineCheckId?: string
  ): { link: LinkField; queryParams?: object } {
    let queryParamsObj = this.buildCheckoutQueryParams(product, promotion, lineCheckId);
    queryParamsObj = Object.assign(queryParamsObj, this.featureFlagService.gbOverrideQueryParams);
    const queryParamsArray = Object.entries(queryParamsObj);
    const queryParams = queryParamsArray.join('&').replace(/,/g, '=');

    return {
      link: {
        url: `${this.config.checkoutUrl}/${this.languageService.current}/start/${product.product_code}${
          queryParamsArray.length > 0 ? `?${queryParams}` : ''
        }`,
        link_type: 'Web',
      },
    };
  }

  public customProductsFromPrismic(): Observable<{ productCode: string; promotionCode: string }[]> {
    throw new Error('Method not implemented.');
  }
}
