import { DOCUMENT, isPlatformBrowser, NgClass } from '@angular/common';
import {
  AfterViewInit,
  APP_ID,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  NgZone,
  PLATFORM_ID,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  GuardsCheckEnd,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { UserService } from 'auth-data-access';
import { BrandService } from 'brand';
import { LoadingComponent } from 'loading';
import { MenuGlobalComponent } from 'menu';
import { ToastsComponent } from 'toast';
import { TooltipGlobalComponent } from 'tooltip';
import { appendNoScriptGoogleTagManager, appendScript, ENVIRONMENT_URLS_CONFIG_TOKEN } from 'utils';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [ToastsComponent, MenuGlobalComponent, TooltipGlobalComponent, NgClass, RouterOutlet, LoadingComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit {
  private router = inject(Router);
  brand = inject(BrandService);
  private userService = inject(UserService);
  private zone = inject(NgZone);
  private matDialog = inject(MatDialog);
  private cdr = inject(ChangeDetectorRef);
  private platformId = inject(PLATFORM_ID);
  private appId = inject(APP_ID);
  private document = inject<Document>(DOCUMENT);
  private config = inject(ENVIRONMENT_URLS_CONFIG_TOKEN);
  loading = true;

  constructor() {
    const platform = isPlatformBrowser(this.platformId) ? 'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${this.appId}`);

    this.router.events.subscribe(resp => {
      if (resp instanceof NavigationStart) {
        this.matDialog.closeAll();
      }
      if (resp instanceof GuardsCheckEnd) this.loading = true;
      if (resp instanceof NavigationEnd) {
        this.loading = false;
        this.setPromoClass(resp);
        if (isPlatformBrowser(this.platformId)) {
          this.zone.runOutsideAngular(() => {
            // Do not scroll to top when NavigationEnd.id is 1 - first page loaded, after hydration
            if (!resp.url.includes('#openModalWithID') && resp.id !== 1) {
              window.scrollTo(0, 0);
            }
          });
        }
      }
      if (resp instanceof NavigationCancel) this.loading = false;
      if (resp instanceof NavigationError) this.loading = false;

      this.cdr.markForCheck();
    });

    //set visitor_id for analytics if there is no visitor_id
    const visitorId = this.userService.getVisitorId();
    if (!visitorId) {
      this.userService.setVisitorId();
    }

    if (isPlatformBrowser(this.platformId)) {
      this.userService.getSubscriptions().subscribe(); // get subscriptions just to set the previously logged in cookie
    } else {
      appendNoScriptGoogleTagManager(this.document, environment.googleTagManagerId);
    }
  }

  ngAfterViewInit() {
    const prismicRepo = this.config.prismicRepo;
    appendScript(this.document, `https://static.cdn.prismic.io/prismic.js?new=true&repo=${prismicRepo}`);
  }

  setPromoClass(e: NavigationEnd) {
    if (/^(\/(en|de|fr|it))?\/blackfriday(\/.+)?/.test(e.urlAfterRedirects)) {
      this.document.body.classList.add('blackfriday');
    } else {
      this.document.body.classList.remove('blackfriday');
    }
  }
}
